<template>
    <div :class="'row' + (needsAbsolutePos ? ' footer-abs':'')">
        <div class="col">
            <p>
                &copy; {{ tY }} Hagebau Veranstaltungen
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageFooter",
    data() {
        return {
            needsAbsolutePos:false
        }
    },
    created() {
        this.$watch('$route.params', () => { 
            this.$nextTick( this.checkPositioning )
        })
        this.$watch('$route.name', () => {
            this.$nextTick( this.checkPositioning )
        })
        this.$watch('$store.state.pages', () => {
            this.$nextTick( this.checkPositioning )
        })
    },
    mounted() {
        const vm = this
        vm.checkPositioning()
        window.addEventListener('resize', vm.checkPositioning)
    },
    beforeDestroy() {
        const vm = this
        window.removeEventListener('resize', vm.checkPositioning)
    },
    methods: {
        checkPositioning() {
            this.needsAbsolutePos = false
            this.$nextTick(() => {
                const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
                const elPos = parseInt(this.$el.getBoundingClientRect().top ,10)
                this.$el.style.setProperty('--content-height', (h - elPos) +'px')
                this.needsAbsolutePos = elPos < (h - parseInt(this.$el.getBoundingClientRect().height ,10))
            })
        }
    },
    computed: {
        tY() {
            const now = new Date()
            return now.getFullYear()
        },
        hideNavigation() {
            return this.$route.matched.some(m => m.hasOwnProperty('meta') && m.meta.hasOwnProperty('noNavigation') && m.meta.noNavigation)
        }
    }
}
</script>

<style scoped lang="scss">
    .row {
        margin-top:0;
        background-color: #333;
        color:#fff;
        padding-top:1rem;
        padding-bottom:2rem;

        p {

            a {
                display: inline-block;
                margin:0 .15em;
            }
        }
    }
    
    .footer-abs {
        margin-top:var(--content-height);
    }
</style>