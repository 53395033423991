<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" viewBox="0 0 840.56 504.89" xml:space="preserve">
        <g>
            <path fill="currentColor" d="M584.86,46.22c-113.96,0-206.32,92.36-206.32,206.32c0,113.95,92.36,206.32,206.32,206.32
            s206.32-92.36,206.32-206.32C791.19,138.58,698.82,46.22,584.86,46.22z M600.44,426.99v-13.87c0-8.61-6.96-15.57-15.57-15.57
            c-8.61,0-15.57,6.96-15.57,15.57v13.87c-84.35-7.47-151.67-74.95-158.91-159.36h13.42c8.6,0,15.57-6.96,15.57-15.57
            s-6.96-15.57-15.57-15.57h-13.35c7.67-83.96,74.82-150.95,158.84-158.39v12.9c0,8.61,6.96,15.57,15.57,15.57
            c8.61,0,15.57-6.96,15.57-15.57v-12.9c84.02,7.44,151.17,74.43,158.84,158.39h-13.34c-8.61,0-15.57,6.96-15.57,15.57
            s6.96,15.57,15.57,15.57h13.42C752.11,352.04,684.78,419.52,600.44,426.99z"></path>
            <path fill="currentColor" d="M677.62,152.03l-92.23,96.65c-0.17,0-0.35-0.03-0.53-0.03c-1.15,0-2.28,0.12-3.37,0.33l-77.19-58.33
            c-5.11-3.83-12.44-2.86-16.33,2.31c-3.89,5.11-2.89,12.47,2.28,16.36l77.17,58.31c0.74,8.98,8.27,16.05,17.44,16.05
            c9.67,0,17.52-7.85,17.52-17.52c0-0.46-0.03-0.91-0.07-1.37l92.22-96.65c4.44-4.68,4.29-12.05-0.39-16.51
            C689.49,147.16,682.09,147.37,677.62,152.03z"></path>
        </g>
        <path fill="currentColor" d="M396.22,419.17H57.51c-4.48,0-8.14-3.66-8.14-8.14v-6.89c0-4.48,3.66-8.14,8.14-8.14h338.7
    c4.48,0,8.14,3.66,8.14,8.14v6.89C404.36,415.51,400.69,419.17,396.22,419.17z"></path>
        <path fill="currentColor" d="M349.2,350.78H111.81c-4.48,0-8.14-3.66-8.14-8.14v-6.89c0-4.48,3.66-8.14,8.14-8.14H349.2
    c4.48,0,8.14,3.66,8.14,8.14v6.89C357.34,347.12,353.68,350.78,349.2,350.78z"></path>
        <path fill="currentColor" d="M332.4,282.4H171.19c-4.48,0-8.14-3.66-8.14-8.14v-6.89c0-4.48,3.66-8.14,8.14-8.14H332.4
    c4.48,0,8.14,3.66,8.14,8.14v6.89C340.54,278.73,336.87,282.4,332.4,282.4z"></path>
    </svg>
</template>

<script>
export default {
    name: "TimeFlysIcon"
}
</script>

<style scoped lang="scss">
    svg path {
        color:inherit;
    }
</style>