<template>
    <div class="errorbox animate-error" v-if="hasErrors">
        <div class="info-box box-inline style-error--transparent">
            <div class="message">
                <p v-for="err in $store.getters.errors">
                    {{ err.text }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Errors",
    computed: {
        hasErrors() {
            return this.$store.getters.errors.length > 0
        }
    }
}
</script>

<style scoped lang="scss">
    .errorbox {
        position: fixed;
        top:0;
        left: 0;
        width:100%;
        background-color:#dc3545;
        color:#ffffff;
        z-index:999;
        
        .message {
            padding:.5em 16px;
        }
    }
</style>