const texts = {
    invalidLogin: {
        de: 'Die Zugangsdaten stimmen nicht.',
        en: 'Incorrect login code.'
    },
    offlineStoreOffline: {
        de: 'Die offline Datenbank kann nicht geöffnet werden. Bitte starten Sie die App neu.',
        en: 'The offline data storage can\'t be accessed. Please restart the app.'
    },
    notInOfflineStore: {
        de: 'Diese Seite ist offline nicht verfügbar. Bitte aktivieren Sie die mobile Datennutzung.',
        en: 'This page is not available in offline mode. Please connect to the Internet.'
    },
    insufficientRights: {
        de: 'Sie haben kein Recht, die Seite anzuzeigen, auf die Sie navigieren wollten.',
        en: 'Insufficient rights to display the page you wanted to navigate to.'
    },
    notAvailableOffline: {
        de: 'Dieser Inhalt kann leider nicht offline angezeigt werden.',
        en: 'Unfortunately this content cannot be displayed while the app is offline.'
    },
    temperature: {
        de:'Temperatur',
        en:'Temperature'
    },
    fulldateformat: {
        de:'dddd, Do MMMM YYYY',
        en:'dddd, MMMM Do YYYY'
    },
    smalldateformat: {
        de:'DD.MM.',
        en:'DD/MM'
    },
    timeformat: {
        de:'HH:mm',
        en:'ha'
    },
    timeformatWithMinutes: {
        de:'HH:mm',
        en:'h:mma'
    },
    winddirection: {
        de:'Windrichtung',
        en:'Wind direction'
    },
    windspeed: {
        de:'Windgeschwindigkeit',
        en:'Wind speed'
    },
    weather: {
        de:'Wetter',
        en:'Weather'
    },
    overview: {
        de:'Veranstaltungen',
        en:'Events'
    },
    overcast: {
        de:'bewölkt',
        en:'overcast clouds'
    },
    clearsky: {
        de:'klarer Himmel',
        en:'clear sky'
    },
    fewclouds: {
        de:'leicht bewölkt',
        en:'few clouds'
    },
    scatteredclouds: {
        de:'heiter bis wolkig',
        en:'clouds'
    },
    lightrain: {
        de:'regnet leicht',
        en:'light rain'
    },
    moderaterain: {
        de:'regnet etwas',
        en:'moderate rain'
    },
    rain: {
        de:'Regen',
        en:'rain'
    },
    lightsnow: {
        de:'schneit leicht',
        en:'light snow'
    },
    snow: {
        de:'Schnee',
        en:'snow'
    },
    thunder: {
        de:'Gewitter',
        en:'thunder'
    },
    forecast: {
        de:'Vorhersage',
        en:'forecast'
    },
    map: {
        de:'Karte',
        en:'Map'
    },
    of: {
        de:'von',
        en:'of'
    },
    duringday: {
        de:'Tagsüber',
        en:'During the day'
    },
    duringnight: {
        de:'In der Nacht',
        en:'At night'
    },
    days: {
        de:'Tage',
        en:'Days'
    },
    hours: {
        de:'Stunden',
        en:'Hours'
    },
    minutes: {
        de:'Minuten',
        en:'Minutes'
    },
    seconds: {
        de:'Sekunden',
        en:'Seconds'
    },
    toEvent: {
        de:'bis zum Event',
        en:'to the event'
    },
    unreadMessages: {
        de:'ungelesene Mitteilungen',
        en:'unread messages'
    },
    noMessages: {
        de:'Noch keine Mitteilungen empfangen.',
        en:'No messages received so far.'
    },
    search: {
        de:'Suchen',
        en:'Search'
    },
    question: {
        de:'Frage',
        en:'Question'
    },
    back: {
        de:'Zurück',
        en:'Back'
    },
    answerQuestions: {
        de:'Fragen beantworten',
        en:'Answer Questions'
    },
    prevQuestion: {
        de:'Zurück',
        en:'Back'
    },
    nextQuestion: {
        de:'Nächste Frage',
        en:'Next Question'
    },
    feedbackLeft: {
        de:'Sie haben an der Umfrage bereits teilgenommen. Vielen Dank für Ihr Feedback!',
        en:'You have already participated in the survey. Thank you for your feedback!'
    },
    feedbackPrivacy: {
        de: 'Bitte beachten Sie, dass Angaben in Freitextfeldern freiwillig sind. Bitte achten Sie darauf, hierüber keine sensiblen \n' +
            'Informationen über sich preiszugeben. Sollten Sie dies dennoch wünschen, werden wir diese sensiblen Angaben vertraulich behandeln.',
        en: 'Please note that information in free text fields is voluntary. Please make sure not to disclose any sensitive information about yourself. \n' +
            'Should you nevertheless wish to do so, we will treat this sensitive information confidentially.'
    },
    readMore: {
        de:'mehr lesen',
        en:'read more'
    },
    logout: {
        de:'Logout',
        en:'Log out'
    },
    pleaseConfirm: {
        de:'Bitte bestätigen',
        en:'Please confirm'
    },
    menu: {
        de:'Menü',
        en:'Menu'
    },
    send: {
        de:'Senden',
        en:'Send'
    },
    enter: {
        de:'Login',
        en:'Enter'
    },
    mailaddress: {
        de:'E-Mail Adresse',
        en:'Email Address'
    },
    logincode: {
        de:'Anmeldecode',
        en:'Login Code'
    },
    sendotherquestion: {
        de: 'Weitere Fragen einreichen',
        en: 'Submit further questions'
    },
    thankYouQuestion: {
        de:'Vielen Dank.',
        en:'Thank you.'
    },
    thankYouFeedback: {
        de:'Vielen Dank für Ihre Teilnahme an unserer Umfrage!',
        en:'Thank you for participating in our survey!'
    },
    questionArea: {
        de:'Bitte wählen Sie ein Thema:',
        en:'Please choose a topic:'
    },
    selectOption: {
        de:'-- Bitte wählen --',
        en:'-- Please choose --'
    },
    feedbackOneChoice: {
        de:`Sie können eine Antwort auswählen.`,
        en:`You can choose one answer.`
    },
    feedbackMultipleChoice: {
        de:`Sie können mehrere Antworten auswählen.`,
        en:`You can choose multiple answers.`
    },
    downloadSuccess: {
        de: `Der Link wurde erfolgreich verschickt.`,
        en: `The link was send successfully.`,
    },
    downloadDescriptionText: {
        de: `Downloadlink per Mail Versenden`,
        en: `Send download link via mail`
    },
    downloadLink: {
        de: `Dateien herunterladen`,
        en: `Download files`
    },
    today: {
        de: 'Heute',
        en: 'Today'
    },
    filterMinspace: {
        de: 'Freie Plätze filtern',
        en: 'Filter minimum free seats'
    },
    filterDays: {
        de: 'Tage filtern',
        en: 'Filter days'
    },
    bookableSlotNotFound: {
        de: 'Ihr Termin ist nicht mehr buchbar',
        en: 'Your selected slot is no longer available'
    }
}

export default texts
