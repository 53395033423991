<template>
    <div :class="'b-page-wrapper'+ pageClass">
        <PageHeader />
        <router-view v-if="!waitingForData"  />
        <div v-if="waitingForData" id="spinner">
            <Spinner v-if="$store.state.isOnline" />
            <div v-if="!$store.state.isOnline && !recheckingContent" style="padding:0 7.5%">{{ $store.getters.tText('notInOfflineStore') }}</div>
        </div>
        
        <transition name="jumpin">
            <span v-show="showJumper" id="topjumper" class="icon icon--arrow_up" @click="gotoTop"></span>
        </transition>
        
        <PageFooter />
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import Spinner from '@/components/Spinner.vue'
import PageFooter from '@/components/PageFooter.vue'
import moment from 'moment-timezone'
import Color from 'color'

export default {
    data() {
        return {
            showJumper: false,
            scrollTop: 0,
            recheckingContent: false
        }
    },
    name: "ContentEvent",
    components: { PageHeader, Spinner, PageFooter },
    created() {
        if (this.$store.state.events.length < 1) {
            this.$store.dispatch('readEventData')
        }

        this.$store.commit('ResetEvent')
        window.addEventListener('scroll', this.setScrollOffset)
        this.$watch('scrollTop', (nPos, oPos) => {
            if (nPos <= 20) {
                this.showJumper = false
            } else if (oPos > nPos) {
                this.showJumper = true
            } else {
                this.showJumper = false
            }
        })
        
        this.$watch('$store.state.tick', (test_moment) => {
            if (this.$store.state.isOnline) {
                const compareMoment = this.$store.getters.eventData.last_fetch
                if (typeof compareMoment === 'object' && compareMoment !== null) {
                    if (test_moment.isAfter(moment(compareMoment).add(2, 'minutes'))) {
                        this.$store.commit('queueUpdate')
                        this.$store.dispatch('readEventDataFromServer')
                    }
                }
            }
        })


    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setScrollOffset)
    },
    methods: {
        setScrollOffset() {
            this.scrollTop = window.scrollY
        },
        gotoTop() {
            window.scrollTo(0, 0)
        }
    },
    computed: {
        pageClass() {
            const waitingclass = this.waitingForData ? ' waiting':''
            
            if (this.$route.hasOwnProperty('name')) {
                return waitingclass + ' page-'+ this.$route.name
            }
            return waitingclass
        },
        waitingForData() {
          return false
        }
    }
}
</script>

<style lang="scss">
    #topjumper {
        position: fixed;
        bottom:2rem;
        display: block;
        right:7.14286%;
        color:var(--highlight-color);
        cursor:pointer;

        &.jumpin-enter-active, &.jumpin-leave-active {
            transition: opacity .5s, bottom 1.5s;
        }
        &.jumpin-enter, &.jumpin-leave-to {
            opacity: 0;
            bottom:-2rem;
        }
    }

    .jumpin-enter-active, .jumpin-leave-active {
        transition: opacity .5s, bottom 1.5s;
    }
    .jumpin-enter, .jumpin-leave-to {
        opacity: 0;
        bottom:0;
    }
    
    #spinner {
        display: flex;
        justify-content:center;
        align-items:center;
        width:100%;
        min-height:600px;
    }
</style>