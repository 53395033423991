<template>
    <video :id="'hagebau-player'+ (playerid ? '-'+ playerid:'')" disablePictureInPicture class="video-js vjs-default-skin vjs-big-play-centered" playsinline controls preload="auto" :poster="poster" data-setup="{}">
        <source :src="src" type="application/x-mpegurl">
        <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a web browser that
            supports HTML5 video.
        </p>
        <Spinner ref="spinner" />
        <PlayButton ref="playbutton" />
    </video>
</template>

<script>
import videojs from 'video.js'
import Spinner from '@/components/Spinner.vue'
import PlayButton from '@/components/PlayButton.vue'

export default {
    name: "VideoPlayer",
    components: { Spinner, PlayButton },
    props: [ 'poster','src','playerid', 'playerOptions' ],
    watch: {
        src() {
            if (typeof this.playerinst === 'undefined' || this.playerinst === null) {
                return
            }

      const vm = this
      this.playerinst.src({
        src: vm.src,
        type: 'application/x-mpegurl'
      })
    }
  },
  mounted() {
    const vm = this
    const VJSSpinner = videojs.getComponent('LoadingSpinner')
    class LoadingSpinner extends VJSSpinner {
      constructor(player, options = {}) {
        super(player, options);
      }
      createEl() {
        return vm.$refs.spinner.$el
      }
    }
    videojs.registerComponent('LoadingSpinner', LoadingSpinner)
    const VJSPlayButton = videojs.getComponent('BigPlayButton')
    class BigPlayButton extends VJSPlayButton {
      constructor(player, options = {}) {
        super(player, options);
      }
      createEl() {
        return vm.$refs.playbutton.$el
      }
    }
    videojs.registerComponent('BigPlayButton', BigPlayButton)

    let opts = { fluid: true };
    if(this.playerOptions) {
      opts = {...opts, ...this.playerOptions};
    }

        this.playerinst = videojs('hagebau-player'+ (this.playerid ? '-'+ this.playerid:''), opts , () => { vm.playerReady(vm.playerinst) })
    },
    methods: {
        playerReady(player) {
            const vm = this
            player.fluid(true)
            player.on('ended', () => {
                vm.$emit('playbackended')
            })
        }
    },
    beforeDestroy() {
        videojs(this.$el).dispose()
    }
}
</script>

<style lang="scss">
    @import "../../style/just_variables";

    // Text, icons, hover states
    $primary-foreground-color: #fff;

    // Control backgrounds (control bar, big play, menus)
    $primary-background-color: #333;
    $primary-background-transparency: 0;

    // Hover states, slider backgrounds
    $secondary-background-color: #959899;
    $secondary-background-transparency: 0.5;

    $text-font-family: inherit;

    // Using the '--' naming for component-specific styles
    $big-play-button--width: 3em !default;
    $big-play-button--height: 1.5em !default;
    $big-play-button--transparency: 0.8 !default;


    @import "node_modules/video.js/src/css/vjs.scss";

    .video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before, .video-js .vjs-control:focus {
        text-shadow: none;
    }
    
    .video-js {
        
        .vjs-play-control {
            background-color:var(--highlight-color);
            color:var(--navigation-background)
        }

        .vjs-picture-in-picture-control {
            display: none;
        }
        
        svg {
            position: absolute;
            top:50%;
            left:50%;
            margin:-50px 0px 0px -50px;
        }

        svg.hagebau-spinner {
            display:none;
        }
        
        &.vjs-seeking, &.vjs-waiting {
            svg.hagebau-spinner {
                display:block;
            }
        }

        .fa-play {
            width:100px;
            height:100px;
        }

        &.vjs-controls-disabled,
        &.vjs-has-started,
        &.vjs-using-native-controls,
        &.vjs-error {
            .fa-play {
                display: none;
            }
        }

        &.vjs-has-started.vjs-paused.vjs-show-big-play-button-on-pause {
            .fa-play {
                display: block;
            }
        }

        .vjs-play-control, .vjs-play-control.vjs-playing, .vjs-play-control.vjs-ended, .vjs-volume-panel, .vjs-mute-control.vjs-vol-0, .vjs-mute-control.vjs-vol-2, .vjs-mute-control.vjs-vol-1 {
            .vjs-icon-placeholder {
                @include make-fa-icon;
            }
        }

        .vjs-icon-play:before, .vjs-play-control .vjs-icon-placeholder:before {
            content: "\f04b";
        }
        
        .vjs-icon-pause:before {
            content: "\f04c";
        }
        
        .vjs-icon-replay:before {
            content: "\f3e5";
        }
        
        .vjs-icon-volume-mute:before {
            content: "\f6a9";
        }
        
        .vjs-icon-volume-high:before {
            content: "\f028";
        }
        .vjs-icon-volume-mid:before {
            content: "\f6a8";
        }
        .vjs-icon-volume-low:before {
            content: "\f027";
        }
        
        
    }
</style>
