<template>
    <div>
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>Debug</span>
                </h1>
            </div>
        </div>
        <section class="row mt-3">
            <div class="col">
                <h3>Verbindung: {{ $store.state.isOnline ? 'Online':'Offline' }}</h3>
                <table class="table" v-if="$store.state.isOnline" aria-describedby="">
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                    <tbody>
                    <tr>
                        <td>GZip-Kompression</td>
                        <td>{{ $store.state.connection.isgzipped ? 'An':'Aus' }}</td>
                    </tr>
                    <tr>
                        <td>Übertragungsrate (ca.)</td>
                        <td>{{ networkSpeed }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>
        <section class="row mt-3">
            <div class="col">
                <h3>Umgebung:</h3>
                <p>{{ $store.state.isWKWebview ? 'iOS auf dem Homescreen':'Nicht iOS oder nicht auf dem Homescreen' }}</p>
            </div>
        </section>
        <section class="row mt-3">
            <div class="col">
                <h3>Stand</h3>
                <table class="table" aria-describedby="">
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                    <tbody>
                    <tr>
                        <td>Login</td>
                        <td>{{ loginString }}</td>
                    </tr>
                    <tr>
                        <td>App-Code</td>
                        <td>{{ $store.state.codeVersion.calendar() }}</td>
                    </tr>
                    <tr>
                        <td>Letzte Neu-Inizialisierung</td>
                        <td>{{ $store.state.launchMoment.calendar() }}</td>
                    </tr>
                    <tr>
                        <td>Event-Daten</td>
                        <td>{{ $store.getters.eventData.last_fetch.calendar() }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>
        <section class="row mt-3">
            <div class="col">
                <h3>Offline Tabellen</h3>
                <p v-if="!dbOpen" style="color:#f00">Datenbank geschlossen</p>
                <table class="table" aria-describedby="">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Objekte</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="st in statistics" :key="'table-'+ st.name">
                        <td>{{ st.name }}</td>
                        <td>{{ st.amount }}</td>
                    </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <h3>Storage Platz</h3>
                <p v-if="storageQuota.used === 0">Unbekannt</p>
                <div v-else>
                    <div class="usagebar">
                        <div class="innerusage" :style="'width:'+ (storageQuota.used * 100 / storageQuota.available) +'%;'"></div>
                    </div>
                    <p><small>{{ Math.round(storageQuota.used * 100 / storageQuota.available) }}% - {{ Math.round(storageQuota.used / 1024 / 1024) }}MB von {{ Math.round(storageQuota.available / 1024 / 1024) }}MB verbraucht.</small></p>
                </div>
                <p>&nbsp;</p>
                <button type="button" class="btn btn-primary" @click="recalcStats">
                    Aktualisieren
                </button>
            </div>
        </section>
        <section class="row mt-3">
            <div class="col">
                <h3>Seiten</h3>
                <table class="table" aria-describedby="">
                    <thead>
                    <tr>
                        <th>DB-ID</th>
                        <th>Typ</th>
                        <th>Name</th>
                        <th>Inhalt</th>
                        <th>Stand</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="p in pagesByNewest" :key="'page-'+ p.objvalueid">
                        <td>{{ p.objvalueid }}</td>
                        <td>{{ p.rptype }}</td>
                        <td>{{ p.pagename_en !== null && p.pagename_en !== '' ? p.pagename_en : p.pagename_de }}</td>
                        <td>{{ (p.content_en === null || p.content_en === '') || (p.content_de === null || p.content_de === '') ? 'leer':'gefüllt' }}</td>
                        <td v-if="p.content_update === null">{{ p.content_update }}</td>
                        <td v-else>{{ moment(p.content_update).calendar() }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </section>

        <section class="row mt-3" v-if="networkErrorsToday.length > 0 || appErrorsToday.length > 0">
            <div class="col">
                <button type="button" class="btn btn-primary" @click="clearErrors">
                    Fehler löschen
                </button>
            </div>
        </section>

        <section class="row mt-3">
            <div class="col">
                <h3>Netzwerkfehler</h3>

                <div v-if="networkErrorsToday.length > 0" style="overflow: auto">
                    <table class="table" aria-describedby="">
                        <thead>
                        <tr>
                            <th>Gestartet</th>
                            <th>Beendet</th>
                            <th>Action</th>
                            <th>Daten</th>
                            <th>Antwort</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="ne in networkErrorsToday" :key="'networkerror-'+ ne.index">
                            <td>{{ moment(ne.timeRequestStarted).format('HH:mm:ss.SSS') }}</td>
                            <td>{{ moment(ne.timeFinished).format('HH:mm:ss.SSS') }}</td>
                            <td>{{ ne.action }}</td>
                            <td>{{ ne.data }}</td>
                            <td>{{ ne.status }} {{ ne.statusText }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p v-else>Keine in den letzten 8 Stunden</p>
            </div>
        </section>

        <section class="row mt-3">
            <div class="col">
                <h3>App-Ausnahmen</h3>

                <div v-if="appErrorsToday.length > 0" style="overflow: auto">
                    <table class="table" aria-describedby="">
                        <thead>
                        <tr>
                            <th>Zeitpunkt</th>
                            <th>Inhalt</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="ae in appErrorsToday" :key="'apperror-'+ ae.index">
                            <td>{{ moment(ae.time).format('HH:mm:ss.SSS') }}</td>
                            <td>{{ ae.reason }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p v-else>Keine in den letzten 8 Stunden</p>

            </div>
        </section>

    </div>
</template>

<script>
import moment from 'moment-timezone'
import _ from 'lodash'
import db from '@/db'

export default {
    name: "DebugPage",
    data() {
        return {
            statistics:[],
            storageQuota: { used: 0, available:0 },
            dbOpen: false
        }
    },
    created() {
        this.recalcStats()
    },
    computed: {
        loginString() {
            if (this.$store.state.credentials.logintime === null || this.$store.state.credentials.logintime === '') {
                return 'Unbekannt'
            }

            return moment(this.$store.state.credentials.logintime).calendar()
        },
        pagesForUpdate() {
            return this.$store.state.pagesLoadTriggered
        },
        pagesByNewest() {
            return _.orderBy(this.$store.state.pages, [ p => p.content_update === null ? '000000000' : p.content_update ],['desc'])
        },
        networkErrorsToday() {
            return _.orderBy(_.filter(this.$store.state.networkErrors, nE => {
                return moment(nE.timeRequestStarted).isSameOrAfter(moment().subtract(8, 'hours'))
            }), [nE => nE.timeRequestStarted.toString() ],['desc'])
        },
        appErrorsToday() {
            return _.orderBy(_.filter(this.$store.state.appErrors, aE => {
                return moment(aE.time).isSameOrAfter(moment().subtract(8, 'hours'))
            }), [aE => aE.time.toString() ],['desc'])
        },
        networkSpeed() {
            if (this.$store.state.connection.networkSpeedKBs === null) {
                return 'Unbekannt'
            } else {
                return (Math.round(this.$store.state.connection.networkSpeedKBs * 10) / 10) + ' KB/s'
            }
        }
    },
    methods: {
        clearErrors() {
            this.$store.commit('clearDebugErrors')
        },
        moment(time) {
            return moment(time)
        },
        seeDatabase() {
            this.dbOpen = db.isOpen()
        },
        recalcStats() {
            this.seeDatabase()

            const storageEstimateWrapper = () => {
                if ('storage' in navigator && 'estimate' in navigator.storage) {
                    return navigator.storage.estimate()
                }

                if ('webkitTemporaryStorage' in navigator &&
                    'queryUsageAndQuota' in navigator.webkitTemporaryStorage) {
                    return new Promise((resolve, reject) => {
                        navigator.webkitTemporaryStorage.queryUsageAndQuota(
                            (usage, quota) => { resolve({usage: usage, quota: quota}) },
                            reject
                        )
                    })
                }

                return Promise.resolve({usage: 0, quota: 0})
            }

            storageEstimateWrapper()
                .then(({usage, quota}) => {
                    this.storageQuota = { used: usage, available: quota }
                })

            this.statistics = []

            db.tables.forEach(t => {
                this.statistics.push({
                    name: t.name,
                    amount: 0
                })
            })

            const countPromises = []

            this.statistics.forEach(dbS => {
                countPromises.push(new Promise((resolve) => {
                    db[dbS.name].count()
                        .then(am => {
                            dbS.amount = am
                            resolve()
                        })
                        .catch(() => {
                            resolve()
                        })
                }))
            })

            Promise.all(countPromises)
                .then(() => {
                    this.statistics = [...this.statistics]
                })
        }
    }
}
</script>

<style scoped lang="scss">
    table {
        width:100%;

        thead {
            tr {
                border-bottom:1px solid #191f22;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #c8cacb;
                td {
                    font-size:80%;
                }
            }
        }
        tr th, tr td {
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }
        }

        tr td {
            &:first-child {
                color: #959899;
            }
            &:last-child {

            }
        }
    }

    .usagebar {
        display: flex;
        background-color:#aaa;
        height:20px;

        .innerusage {
            background-color: var(--highlight-color);
        }
    }
</style>