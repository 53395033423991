<template>
    <div class="pid__topbar-navigation">
        <div class="navigation-topbar-mobile">
            <MobileLogo :hideNavigation="hideNavigation" :homelink="homelink" />
            <div v-if="!hideHomeNavigation" class="globalBacklink" @click.prevent="goBackInHistory">
                <button class="btn btn-outline-default btn-sm" type="button">
                    <span class="navigation-topbar-mobile__icon navigation-topbar-mobile__icon--active icon icon--arrow-left-thin"></span>
                </button>
            </div>
            <ul v-if="!hideNavigation" class="navigation-topbar-mobile__items">
                <li class="navigation-topbar-mobile__item navigation-topbar-mobile__item--application">
                    <button class="btn btn-outline-primary btn-sm" type="button" @click.stop="showContext = true">
                        {{ $store.getters.tText('menu') }}
                    </button>
                </li>
            </ul>
            <div v-if="needsLangNav" class="m-101__menuitem m-101__trigger" role="button" data-target-menu="models">
                <div class="m-101__button__text">
                    <span v-if="$store.state.language === 'en'" @click.prevent="$store.commit('setLanguage', 'de')">
                        de
                    </span>
                    <span v-if="$store.state.language === 'de'" @click.prevent="$store.commit('setLanguage', 'en')">
                        en
                    </span>
                </div>
            </div>
            <transition name="fade">
                <div class="navigation-topbar-mobile__flyout navigation-topbar-mobile__flyout--application" v-show="showContext" v-if="!hideNavigation" style="z-index: 1101">

                    <div class="navigation-topbar-mobile__flyout-header navigation-topbar-mobile__flyout-header--back" @click.prevent="goBackInNav">
                        <button class="btn btn-outline-default btn-sm" type="button">
                            <span class="navigation-topbar-mobile__icon navigation-topbar-mobile__icon--active icon icon--arrow-left-thin"></span>
                        </button>
                    </div>
                    <ul class="navigation-topbar-mobile__flyout-menu navigation-topbar-mobile__flyout-menu--application">

                        <NavItem v-for="item in useNavItems" :key="'mni_' + item.index" :item="item" @linkklicked="showContext = false" />

                        <li class="navigation-topbar-mobile__flyout-item navigation-topbar-mobile__flyout-item--thirdtab navigation-topbar-mobile__flyout-item--hidden">
                            <a class="navigation-topbar-mobile__flyout-link" href="#">
                                <span class="navigation-topbar-mobile__flyout-link-title "></span>
                                <span class="navigation-topbar-mobile__flyout-icon navigation-topbar-mobile__flyout-icon--right icon icon--arrow-right-thin"></span>
                            </a>
                        </li>
                    </ul>

                    <div class="navigation-topbar-mobile__flyout-footer navigation-topbar-mobile__flyout-footer--hidden">
                        <ul class="navigation-topbar-mobile__flyout-footer-nav"></ul>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import NavItem from '@/components/NavItemMobile.vue'
import MobileLogo from '@/components/MobileLogo.vue'

export default {
    name: "MobileNavigation",
    components: { NavItem, MobileLogo },
    data() {
        return {
            showContext:false
        }
    },
    created() {
        this.$watch('$route', (nroute) => { this.showContext = false })
        this.$watch('showContext', (newV) => {
            const appBody = document.querySelector('body')
            if (newV) {
                appBody.style.overflow = 'hidden'
                this.$store.commit('setMobileNavigationParent', 0)
            } else {
                appBody.style.overflow = ''
            }
        })
    },
    computed: {
        mainContextClasses() {
            return this.showContext ? 
                'navigation-topbar-mobile__flyout navigation-topbar-mobile__flyout--application' : 
                'navigation-topbar-mobile__flyout navigation-topbar-mobile__flyout--application navigation-topbar-mobile__flyout--hidden'
        },
        homelink() {
            return '/events';
        },
        needsLangNav() {
            return this.$store.state.languagesAvailable.length > 1
        },
        useNavItems() {
            return this.$store.getters.navitems( this.$store.state.mobileNavDepth )
        },
        hideHomeNavigation() {
            return this.hideNavigation || (this.$route.name && this.$route.name === 'home' && this.$store.getters.eventData.back_behavior > 1)
        },
        hideNavigation() {
            return this.$route.matched.some(m => m.hasOwnProperty('meta') && m.meta.hasOwnProperty('noNavigation') && m.meta.noNavigation)
        }
    },
    methods: {
        goBackInNav() {
            if (this.$store.state.mobileNavDepth < 1) {
                this.showContext = false
            } else {
                const currentParent = _.find(this.$store.state.pages, p => p.objvalueid === this.$store.state.mobileNavDepth)
                if (currentParent && currentParent.parentid > 0) {
                    this.$store.commit('setMobileNavigationParent', currentParent.parentid)
                    return
                }
            }

            this.$store.commit('setMobileNavigationParent', 0)
        },
        goBackInHistory() {
            const linkType = this.$store.getters.eventData.back_behavior
            switch (linkType) {
                case 1:
                    this.$router.go(-1)
                    break;
                case 2:
                    this.$router.push('/event/'+ this.$store.state.eventid)
                    break;
                default:
                    break;
            }
            
        }
    }
}
</script>

<style scoped lang="scss">
    @use "sass:math";
    @import "../../style/just_variables";

    .pid__topbar-navigation {
        position:relative;
    }
    
    .pid__topbar-navigation .navigation-topbar-mobile__crest {
        position: relative;
        background-color:transparent;
        box-shadow: none;

        margin:0 auto;
        display:block;
    }

    .navigation-topbar-mobile__items {
        position: absolute;
        right:0;
        top:0;
    }
    
    .globalBacklink {
        margin:1rem 0;
        position:absolute;
        display: inline-block;
        cursor: pointer;
        z-index: 0;
        top:0;
        left:0;
    }

    .navigation-topbar-mobile__flyout.navigation-topbar-mobile__flyout--application {
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        overflow-y: auto;
        background-color: #fff;
        z-index: 1101;
        opacity: .99;

        ul, ul li {
            margin:0;
            list-style: none;
        }

        ul {
            padding:0;

            li.navigation-topbar-mobile__flyout-item--thirdtab {
                display: none;
            }
        }

        &.fade-enter-active, &.fade-leave-active {
            transition: opacity .5s, left .5s;
        }
        &.fade-enter, &.fade-leave-to {
            opacity: 0;
            left:500px;
        }
    }



    .navigation-topbar-mobile__items {
        margin:1rem 0;

        li.navigation-topbar-mobile__item {
            list-style: none;
        }
    }

    .navigation-topbar-mobile__flyout-header--back {
      padding:(math.div($grid-gutter-width, 2));
    }
</style>