<template>
    <section>
        <div class="row">
            <div class="col">
                <h2 class="-pui-text-size-2-thin pui-text--align-center pui-text--wrap pui-pb--24">
                    {{ weatherOnSelectedDay.day.format( $store.getters.tText('fulldateformat') ) }}
                </h2>
            </div>
        </div>

        <div class="row">
            <WeatherEntry v-for="wE in weatherOnSelectedDay.entries" :key="wE.dt" :eW="wE" />
        </div>

        <div class="row fb">
            <div class="col">
                <button type="button" class="btn btn-primary btn btn-block back-button" @click.prevent="$router.back()">
                    {{ $store.getters.tText('back') }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import WeatherEntry from '@/components/WeatherEntry.vue'
import moment from 'moment-timezone'
import _ from 'lodash'

export default {
    name: "WeatherSingleDay",
    components: {
        WeatherEntry
    },
    computed: {
        weatherOnSelectedDay() {
            const weatherByDay = []
            
            if (!this.$route.params || !this.$route.params.hasOwnProperty('weatherdate')) {
                return weatherByDay
            }
            
            const now = moment().tz(this.$store.getters.eventData.timezone)
            const pickedDay = moment.tz( this.$route.params.weatherdate + ' 00:00:00', this.$store.getters.eventData.timezone)

            if (this.$store.state.weatherData !== null && this.$store.state.weatherData.hasOwnProperty('list') && this.$store.state.weatherData.list.length > 0) {
                this.$store.state.weatherData.list.forEach(weatherOnSelectedDay => {
                    const dateMoment = moment.utc(weatherOnSelectedDay.dt_txt.replace(/ /, 'T')).tz(this.$store.getters.eventData.timezone)
                    
                    if (!dateMoment.isSame(pickedDay, 'day')) {
                        return
                    }

                    if (dateMoment.isAfter(now)) {
                        let useDay = false
                        if (
                            !weatherByDay.some(sD => {
                                const sameDay = dateMoment.isSame(sD.day, 'day')
                                useDay = sD
                                return sameDay
                            })
                        ) {
                            weatherByDay.push({
                                day: dateMoment,
                                entries: []
                            })

                            useDay = weatherByDay[((weatherByDay.length - 1))]
                        }

                        useDay.entries.push(weatherOnSelectedDay)
                    }
                })
            }

            if (weatherByDay.length > 0) {
                return _.head(weatherByDay)
            }
            
            return {
                day: pickedDay,
                entries: []
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .fb {
        padding-bottom:2rem;
    }
</style>