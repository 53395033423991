<template>
    <div>
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>{{ pageData.pagename }}</span>
                </h1>
            </div>
        </div>
        <section class="row mt-5 mb-5">
            <div class="col-6 offset-3">
                <div class="qrcode" id="qrcode" v-html="qrcodesvg">
                </div>
            </div>
        </section>
        <section v-html="content"></section>
        <SubpageButtons />
    </div>
</template>

<script>
import SubpageButtons from '@/components/SubpageButtons.vue'
import QRCode from "qrcode-svg";

export default {
    name: "QRCode",
    data() {
        return {
          qrcodesvg: "",
        }
    },
    components: { SubpageButtons },
    computed: {
        pageData() {
            return this.$store.getters.selectedPage( 'qrcode' )
        },
        content() {
            let pageDataContentHTML = this.pageData.content

            if (typeof pageDataContentHTML !== "string") {
                return ''
            }

            return pageDataContentHTML.replace(/col-xs-(\d+)/g, 'col-$1')
        }
    },
    methods: {

        async redrawQR() {
          const qrcodeInst = new QRCode({
            content: this.$store.state.credentials.barcode[this.$store.state.eventid],
            padding: 0,
            width: 100,
            height: 100,
            color: "black",
            background: "white",
            join: true,
            ecl: "M",
            swap: true // Das ist ganz wichtig!! Ohne konnte man die Codes nicht scannen!
          })

          this.qrcodesvg = await qrcodeInst.svg()
        }
    },
    created() {
      this.$watch('$store.state.credentials.qrcode', this.redrawQR)
    },
    updated() {
      const tsvg = this.$el.querySelector('#qrcode svg')
      if (tsvg) {
        tsvg.setAttribute("width", "100%")
        tsvg.setAttribute("height", "100%")
        tsvg.setAttribute("viewBox", "0 0 100 100")
        tsvg.setAttribute("preserveAspectRatio", "xMinYMin meet")
      }
    },
    mounted() {
      this.$nextTick(this.redrawQR)
    }
}
</script>

<style lang="scss">
    .qrcode svg {
        display: block;
        width:100%;
        height:auto;

    }
    
    .qrcode-realtext {
        padding:2em 0 0;
        text-align: center;
    }
</style>