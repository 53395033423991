<template>
    <div class="gallery-wrap">
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>{{ pageData.pagename }}</span>
                </h1>
            </div>
        </div>
        <div class="gallery-prev">
            <router-link 
                v-for="pI in sortedImages" :key="'prev'+ pI.name" :class="'gallery-previmage'+ (pI.type === 'img' ? ' prev-for-image':' prev-for-video')"
                :style="'background-image:url('+ pI.prev +')'"
                :to="createRoute(pI)"
                replace
            >
                <PlayButton v-if="pI.type === 'video'" />
            </router-link>
        </div>
        <div v-if="($route.params.image || $route.params.video) && !hideOverlay" class="dark-overlay" ref="overlay">
            <div v-if="$route.name === 'singleimage' && $store.state.isOnline" ref="imagedisplay" class="full-image">
                <img :src="currentSource" @click="hideSelection" alt="" />
            </div>
            <div v-if="$route.name === 'singlevideo' && $store.state.isOnline" class="full-video">
                <VideoPlayer
                    @playbackended="hideSelection"
                    :src="currentSource" 
                    :poster="'/hagebau/events/videos/pagetemplate/'+ $route.params.pid +'/'+ $route.params.video +'/poster.jpg'"
                />
            </div>
            <div v-if="!$store.state.isOnline" class="offline-error">
                {{ $store.getters.tText('notAvailableOffline') }}
            </div>

            <div v-if="$store.state.isOnline" class="item-nav">
                <router-link class="prev-item" v-if="prevItem" :to="createRoute(prevItem)" ref="previtem" replace></router-link>
                <router-link class="next-item" v-if="nextItem" :to="createRoute(nextItem)" ref="nextitem" replace></router-link>
            </div>
            <div class="hide-icon">
                <span class="icon icon--close" @click="hideSelection"></span>
            </div>
        </div>
    </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue'
import PlayButton from '@/components/PlayButton.vue'
import _ from 'lodash'

export default {
    name: "Gallery",
    data() {
        return {
            hideOverlay:false
        }
    },
    components: { VideoPlayer, PlayButton },
    mounted() {
        const vm = this
        vm.calcWidth()
        window.addEventListener('resize', vm.calcWidth)
        this.$watch('$route.name', (nroute, oroute) => {
            if (nroute === 'singlevideo' || oroute === 'singlevideo') {
                this.$nextTick(() => {
                    this.hideOverlay = true
                    this.$nextTick(() => {
                        this.hideOverlay = false
                    })
                })
            }
        })
    },
    methods: {
        createRoute(preventry) {
            return {
                name:(preventry.type === 'img' ? 'singleimage':'singlevideo'),
                params:(preventry.type === 'img' ? {
                    eventid: this.$route.params.eventid,
                    pid: this.$route.params.pid,
                    image: preventry.name
                }:{
                    eventid: this.$route.params.eventid,
                    pid: this.$route.params.pid,
                    video: preventry.name
                })
            }
        },
        calcWidth() {
            const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
            this.$el.style.setProperty('--third-width', (w / 3) +'px')
            const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
            this.$el.style.setProperty('--full-hight', h +'px')
        },
        hideSelection(e) {
            this.$router.replace('/event/'+ this.$route.params.eventid +'/c/'+ this.$route.params.pid +'/g')
        }
    },
    computed: {
        currentSource() {
            if (this.$route.name === 'singlevideo' && this.$route.params && this.$route.params.video) {
                return '/hagebau/events/videos/pagetemplate/'+ this.$route.params.pid +'/'+ this.$route.params.video +'/index.m3u8'
            }
            
            if (this.$route.name === 'singleimage' && this.$route.params && this.$route.params.image) {
                return '/projects/hagebau/events/images/gallery/'+ this.$route.params.pid +'/'+ this.$route.params.image
            }
            
            return false
        },
        pageData() {
            return this.$store.getters.selectedPage( parseInt(this.$route.params.pid,10) )
        },
        sortedImages() {
            const page = this.$store.getters.selectedPage( parseInt(this.$route.params.pid,10) )
            if (page.meta && page.meta.gallery) {
                return _.orderBy(page.meta.gallery, ['type', 'name', 'ftime'], ['desc', 'asc', 'asc'])
            }
            
            return []
        },
        prevItem() {
            if (!this.$route.name || (this.$route.name !== 'singleimage' && this.$route.name !== 'singlevideo')) {
                return null
            }
            const page = this.$store.getters.selectedPage( parseInt(this.$route.params.pid,10) )

            if (page.meta && page.meta.gallery) {
                const vm = this
                const orderedGallery = _.orderBy(page.meta.gallery, ['type', 'name', 'ftime'], ['desc', 'asc', 'asc'])
                let prevItem = null
                orderedGallery.some(gI => {
                    if (
                        (gI.type === 'video' && vm.$route.name === 'singlevideo' && gI.name === vm.$route.params.video) ||
                        (gI.type === 'img' && vm.$route.name === 'singleimage' && gI.name === vm.$route.params.image)
                    ) {
                        return true
                    } else {
                        prevItem = gI
                        return false
                    }
                })
                
                return prevItem
            }
            
            return null
        },
        nextItem() {
            if (!this.$route.name || (this.$route.name !== 'singleimage' && this.$route.name !== 'singlevideo')) {
                return null
            }
            const page = this.$store.getters.selectedPage( parseInt(this.$route.params.pid,10) )

            if (page.meta && page.meta.gallery) {
                const vm = this
                const orderedGallery = _.orderBy(page.meta.gallery, ['type', 'name', 'ftime'], ['desc', 'asc', 'asc'])
                let nextItem = null
                let foundme = false
                orderedGallery.some(gI => {
                    if (foundme) {
                        nextItem = gI
                        return true
                    } else {
                        if (
                            (gI.type === 'video' && vm.$route.name === 'singlevideo' && gI.name === vm.$route.params.video) ||
                            (gI.type === 'img' && vm.$route.name === 'singleimage' && gI.name === vm.$route.params.image)
                        ) {
                            foundme = true
                        }
                    }
                    
                    return false
                })

                return nextItem
            }
            
            return null
        }
    },
    beforeDestroy() {
        const vm = this
        window.removeEventListener('resize', vm.calcWidth)
    }
}
</script>

<style scoped lang="scss">
    @import "../../style/just_variables";

    .gallery-wrap {
        --third-width:32px;
        --full-hight:400px;
    }
    
    .gallery-prev {
        @include make-row();
        padding:0;
        display: flex;
        flex-wrap: wrap;
        align-content:stretch;
        position: relative;
        
        .gallery-previmage {
            width:var(--third-width);
            height:var(--third-width);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display:flex;
            flex-direction: column;
            justify-content:center;
            align-items:center;
            align-content:center;

            color:var(--highlight-color);
            
            svg {
                width:50%;
                height:auto;
            }
        }
    }
    
    .dark-overlay {
        position: fixed;
        top:0;
        left:0;
        width:100%;
        min-height:var(--full-hight);
        background:rgba(0,0,0,.75);
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        overflow: auto;
        z-index: 100;
        
        .full-video {
            width:100%;
            height:auto;
        }
        
        .full-image {
            width:100%;
            max-width:var(--full-hight);
            user-select: all;

            img {
                display:block;
                width:100%;
                height:auto;

                -webkit-touch-callout: default;
            }
        }

        @media (orientation: landscape) {
            .full-image {
                height:var(--full-hight);
                width:auto;
                max-width:100%;

                img {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
    
    .offline-error {
        display: block;
        width:100%;
        background-color: #191f22;
        color: #84868c;
        padding-top: 1em;
        padding-bottom: 1em;
        padding-right: 7.14286% !important;
        padding-left: 7.14286% !important;
        border-left:2px solid var(--highlight-color);
    }
    
    .hide-icon {
        color:#fff;
        position: absolute;
        left:0;
        top:1em;
        padding-right: 7.14286%;
        padding-left: 7.14286%;
        width:100%;

        &::after {
            content:' ';
            clear: both;
            display: table-cell;
        }
        
        span {
            @include make-fa-icon;
            &::before {
                content: "\f00d";
            }

            display: block;
            float:right;
            font-size:1.5rem;
            height:auto;
        }
    }
    
    .item-nav {
        position: absolute;
        left:0;
        bottom:1em;
        padding-right: 7.14286%;
        padding-left: 7.14286%;
        width:100%;
        font-size: 2rem;
        
        &::after {
            content:' ';
            clear: both;
            display: table-cell;
        }
        
        a {
            display: block;
            color: var(--highlight-color);
            &.prev-item {
                float:left;
                
                &::before {
                    @include make-fa-icon;
                    
                    width: 1em;
                    content: "\f060";
                }
            }
            
            &.next-item {
                float:right;

                &::after {
                    @include make-fa-icon;

                    width: 1em;
                    content: "\f061";
                }
            }
        }
    }
        
</style>