<template>
    <div class="b-shownotice">
        <MobileNavigation />
        <div class="b-page-overlay"></div>
        <div class="m-00-header m-00-main-navigation-available desktop-navigation">
            <div v-if="showeventitLogo" class="m-00-header-lined-crest">
                <span class="m-00-crest-wrapper-svg">
                    <router-link class="m-00-crest-svg" title="Hagebau" :to="{ path:homelink }">
                        &nbsp;
                    </router-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import MobileNavigation from '@/components/MobileNavigation.vue'

export default {
    name: "PageHeader",
    components: { MobileNavigation },
    computed: {
        homelink() {
          return '/events';
        },
        showeventitLogo() {
            const eventColors = this.$store.getters.eventData.colors
            if (!eventColors.event_color || eventColors.event_color === '' || eventColors.event_color === '#fff' || eventColors.event_color === '#ffffff') {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>

</style>