<template>
    <section v-if="subpages.length > 0" class="fnav-bottom">
            <div v-if="toggleButton">
                <div v-for="item in subpages" :key="'mni_' + item.index" class="row mt-2">
                    <div class="col">
                        <router-link :class="'btn btn-primary btn btn-block' + (item.index === 'back' ? ' back-button':'')" :to="{ path:$store.getters.linkToPage(item.objvalueid) }">
                            {{ item.pagename }}
                        </router-link>
                    </div>
                </div>
            </div>
    </section>
</template>

<script>
import _ from 'lodash'

export default {
    name: "SubpageButtons",
    data() {
        return {
            toggleButton: true
        }
    },
    created() {
        const blurButtons = () => {
            this.toggleButton = false
            this.$nextTick(() => {
                this.toggleButton = true
            })
        }
        
        this.$watch('$route.name', blurButtons)
        this.$watch('$route.params', blurButtons)
    },
    computed: {
        subpages() {
            const vm = this
            const previtem = []
            const me = _.find(this.$store.state.pages, p => p.objvalueid === parseInt(vm.$route.params.pid,10) )
            if (me && me.parentid > 0) {
                const myparent = _.find(this.$store.state.pages, p => p.objvalueid === me.parentid )
                previtem.push({
                    objvalueid: myparent.objvalueid,
                    pagename: this.$store.getters.tText('back'),
                    index: 'back'
                })
            }
            
            return [
                ...this.$store.getters.navitems( parseInt(this.$route.params.pid,10) ),
                ...previtem
            ]
        }
    }
}
</script>

<style scoped lang="scss">
    .padding-same--24 + .padding-same--24 {
        margin-top: 24px;
    }
    
    .b-standard-module {
        padding-bottom: 5px;
    }

    .fnav-bottom {
        margin-top:0;
        padding-top: 0;
        padding-bottom:2em;
    }
</style>
