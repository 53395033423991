import Dexie from 'dexie'

import appname from '@/appname'

const db = new Dexie('hagebauEvents'+ appname)
db.version(1).stores({
    user: `&objvalueid, groupid, firstname, lastname`,
    event: `&objvalueid, guesteventid, name_en, name_de, timezone, startdate, enddate`,
    page: `&objvalueid, eventid, internalname, pagename_en, pagename_de, parentid, showstop`,
    feedbackquestion: `++id, objvalueid, eventid, question_type`,
    feedbackanswer: `++id, [objvalueid+eventid+pid], objvalueid, eventid, pid`,
    weather: `++id, [lat+lon], age`,
    galleryImage: `++id, pageid, name`,
    location: `&objvalueid, eventid, locationname_en, locationname_de, locationtype, [location_lat+location_lon], pageid, address, plz, country, city`
})


class User {
    constructor() {
        this.objvalueid = Number
        this.groupid = Number
        this.firstname = String
        this.lastname = String
        this.salutation = Number
        this.title = Number
        this.language = String
        this.lastroute = String
        this.logintime = String
        this.testuser = Boolean
        this.barcode = String
        this.jwt = String
    }

    save() {
        return db.user.put(this)
    }
}

db.user.mapToClass(User)

class Event {
    constructor() {
        this.objvalueid = Number
        this.guesteventid = Number
        this.name_en = String
        this.name_de = String
        this.timezone = String
        this.startdate = String
        this.enddate = String
        this.weather_lat = Number
        this.weather_lon = Number
        this.weather_radius = Number
        this.weather_cityname_en = String
        this.weather_cityname_de = String
        this.map_lat = Number
        this.map_lon = Number
        this.map_radius = Number
        this.map_min_zoom = Number
        this.map_max_zoom = Number
        this.map_cityname_en = String
        this.map_cityname_de = String
        this.event_color = String
        this.event_color_contrast = String
        this.color_kachel_default = String
        this.color_kachel_default_text = String
        this.splash_image = String
        this.last_fetch = String
        this.group_color = String
        this.back_behavior = Number
        this.placeholders = Array
    }

    save() {
        return db.event.put(this)
    }
}

db.event.mapToClass(Event)

class Page {
    constructor() {
        this.objvalueid = Number
        this.eventid = Number
        this.pagename_en = String
        this.pagename_de = String
        this.content_de = String
        this.content_en = String
        this.parentid = Number
        this.showstop = String
        this.sortpos = Number
        this.icon = String
        this.timezone = String
        this.rptype = Number
        this.content_update = String
        this.meta = String
        this.is_kachel = Number
        this.kachel_color = String
        this.kachel_image = String
        this.kachel_image_checksum = String
        this.kachel_pos = Number
        this.kachel_size = Number
        this.searchable = Boolean
        this.force_save_offline = Boolean
    }

    save() {
        return db.page.put(this)
    }
}

db.page.mapToClass(Page)

class Location {
    constructor() {
        this.objvalueid = Number
        this.eventid = Number
        this.locationname_en = String
        this.locationname_de = String
        this.markertext_en = String
        this.markertext_de = String
        this.locationtype = Number
        this.location_lat = Number
        this.location_lon = Number
        this.location_radius = Number
        this.pageid = Number
        this.address = String
        this.plz = String
        this.country = String
        this.city = String
        this.phone = String
    }

    save() {
        return db.location.put(this)
    }
}

db.location.mapToClass(Location)

class Weather {
    constructor() {
        this.id = Number
        this.lat = Number
        this.lon = Number
        this.data = String
        this.age = Date
    }
    
    save() {
        if (typeof this.data !== 'string' && typeof this.data === 'object') {
            this.data = JSON.stringify(this.data)
        }
        return db.weather.put(this)
    }

    getData() {
        return JSON.parse(this.data)
    }
}

db.weather.mapToClass(Weather)

class GalleryImage {
    constructor() {
        this.id = Number
        this.pageid = Number
        this.name = String
        this.sortpos = Number
        this.desc_de = String
        this.desc_en = String
        this.image_prev = String
        this.image_full = String
    }

    save() {
        return db.galleryImage.put(this)
    }
}


class FeedbackQuestion {
    constructor() {
        this.id = Number
        this.objvalueid = Number
        this.eventid = Number
        this.pid = Number
        this.caption_de = String
        this.rating_options_de = Array
        this.question_type = Number
        this.sortpos = Number
    }

    save() {
        return db.feedbackquestion.put(this)
    }
}

db.feedbackquestion.mapToClass(FeedbackQuestion)

class FeedbackAnswer {
    constructor() {
        this.id = Number
        this.objvalueid = Number
        this.eventid = Number
        this.pid = Number
        this.answer = String
        this.sent = Boolean
        this.allowed_to_send = Boolean
        this.is_fresh = Boolean
    }

    save() {
        return db.feedbackanswer.put(this)
    }
}

db.feedbackanswer.mapToClass(FeedbackAnswer)


db.open()

export default db
export { User, Event, Weather, Page, Location, GalleryImage, FeedbackAnswer, FeedbackQuestion }