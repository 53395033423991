<template>
    <router-link :class="'overviewevent '+ eventsize" :style="eventStyle" :to="{ path:$store.getters.linkToEvent(event.objvalueid) }">
      <div>
        <span class="name">{{ event.name_de }}</span><br>
        <span class="date">{{ callDate(event.startdate, event.enddate) }}</span>
      </div>
      <span class="event-icon">
        <svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-calendar-alt fa-w-14" role="img" aria-hidden="true" viewBox="0 0 448 512" focusable="false" data-icon="calendar-alt" data-prefix="far"><path fill="currentColor" d="M 148 288 h -40 c -6.6 0 -12 -5.4 -12 -12 v -40 c 0 -6.6 5.4 -12 12 -12 h 40 c 6.6 0 12 5.4 12 12 v 40 c 0 6.6 -5.4 12 -12 12 Z m 108 -12 v -40 c 0 -6.6 -5.4 -12 -12 -12 h -40 c -6.6 0 -12 5.4 -12 12 v 40 c 0 6.6 5.4 12 12 12 h 40 c 6.6 0 12 -5.4 12 -12 Z m 96 0 v -40 c 0 -6.6 -5.4 -12 -12 -12 h -40 c -6.6 0 -12 5.4 -12 12 v 40 c 0 6.6 5.4 12 12 12 h 40 c 6.6 0 12 -5.4 12 -12 Z m -96 96 v -40 c 0 -6.6 -5.4 -12 -12 -12 h -40 c -6.6 0 -12 5.4 -12 12 v 40 c 0 6.6 5.4 12 12 12 h 40 c 6.6 0 12 -5.4 12 -12 Z m -96 0 v -40 c 0 -6.6 -5.4 -12 -12 -12 h -40 c -6.6 0 -12 5.4 -12 12 v 40 c 0 6.6 5.4 12 12 12 h 40 c 6.6 0 12 -5.4 12 -12 Z m 192 0 v -40 c 0 -6.6 -5.4 -12 -12 -12 h -40 c -6.6 0 -12 5.4 -12 12 v 40 c 0 6.6 5.4 12 12 12 h 40 c 6.6 0 12 -5.4 12 -12 Z m 96 -260 v 352 c 0 26.5 -21.5 48 -48 48 H 48 c -26.5 0 -48 -21.5 -48 -48 V 112 c 0 -26.5 21.5 -48 48 -48 h 48 V 12 c 0 -6.6 5.4 -12 12 -12 h 40 c 6.6 0 12 5.4 12 12 v 52 h 128 V 12 c 0 -6.6 5.4 -12 12 -12 h 40 c 6.6 0 12 5.4 12 12 v 52 h 48 c 26.5 0 48 21.5 48 48 Z m -48 346 V 160 H 48 v 298 c 0 3.3 2.7 6 6 6 h 340 c 3.3 0 6 -2.7 6 -6 Z" /></svg>
      </span>
    </router-link>
</template>

<script>

import moment from 'moment-timezone'

export default {
    name: "OverviewEvent",
    props: ['event'],
    components: {},
    methods: {
      callDate(startdate, enddate) {
        const date1 = new Date(startdate);
        const date2 = new Date(enddate);

        const start = moment(date1).format("DD.MM.YYYY");
        const end   = moment(date2).format("DD.MM.YYYY");

        if(start == end){
          return moment(date1).format("DD.MM.YYYY");
        }else{
          return moment(date1).format("DD.MM") +'-'+moment(date2).format("DD.MM.YYYY");
        }

      },
    },
    computed: {

        eventsize() {
            let useSize = 12
            return 'k-'+ useSize
        },
        eventStyle() {

            let bgcolor = 'var(--event-background)'
            if(this.event.event_color){
                let bgcolor = this.event.event_color
            }

            if (bgcolor === '' || bgcolor === null) {
                bgcolor = 'var(--event-background)'
            } else {
                bgcolor = bgcolor.replace(/ /,'')
            }

            let imageAdd = ''
            if (this.event.splash_image && this.event.splash_image !== '') {
                imageAdd = 'background-image:url('+ this.event.splash_image +');background-repeat:no-repeat;background-position:center;background-size:cover;';
            }
            return 'background-color:'+ bgcolor +';'+ imageAdd
        }
    }
}
</script>

<style scoped lang="scss">

    @use "sass:math";
    @import "../../style/just_variables";

    .overviewevent {
        position: relative;
        display: flex;
        overflow: hidden;
        padding:(math.div($grid-gutter-width, 2));
        color:var(--event-color);
        border:1px solid var(--group-color);

        flex-direction: column-reverse;
        justify-content:space-between;
        min-height:200px;

        span.name {
            font-size: 1.2rem;
            line-height: 2rem;

        }

        svg {
            overflow: visible;
        }

      .event-icon {
        display: inline-block;
        width:50%;
        padding:16px;
        svg {
          width:100%;
          max-width:64px;
          height:auto;

          path {
            fill:var(--event-color);
          }
        }
      }

    }


</style>