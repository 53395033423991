let store = {}

import appname from '@/appname'

const setCrudStore = cStore => { store = cStore }

const tokenTest = (res) => {
    if (res.hasOwnProperty('error') && (res.error === 'Token invalid' || res.error === 'No Event')) {
        store.commit('setJWT', null)
        store.dispatch('cleanCache')
        
        store.commit('clearErrors')
        
        if (res.error === 'Token invalid') {
            store.commit('appendError', {de: 'Login abgelaufen',en:'Log in expired'})
        }
        if (res.error === 'No Event') {
            store.commit('appendError', {de: 'Keine Veranstaltung gefunden',en:'App content not found'})
        }
    }
}

const crud = (action, data = {}) => {
    data.action = action

    const timeRequestStarted = new Date()
    
    return fetch('/projects/hagebau/events/scripts/eventapp/', {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + (store.state.credentials.jwt ? store.state.credentials.jwt : ''),
            "Appname": appname,
            "X-ACV": store.state.codeVersion.format()
        },
        redirect: "follow",
        referrer: "no-referrer",
        body: JSON.stringify(data)
    })
        .then(response => {
            const timeRequestDone = new Date()

            store.commit('setIfGZipped', response.headers.has('content-encoding') && response.headers.get('content-encoding') === 'gzip')

            if (response.headers.has('x-api-content-length')) {
                let length = parseFloat(response.headers.get('x-api-content-length'))
                if (length >= 262144) {
                    // Mindestens 250 KB großes Request
                    let speed = length / ((timeRequestDone.getTime() - timeRequestStarted.getTime()) / 1000) / 1024
                    store.commit('setNetworkSpeed', speed)
                }
            }

            const res2 = response.clone()

            if (response.headers.has('X-Issue-Bearer')) {
                store.commit('setJWT', response.headers.get('X-Issue-Bearer'))
            }

            const res = response.json()
            
            if (!response.ok && !response.redirected) {
                store.commit('logNetworkError', { timeRequestStarted, timeFinished: new Date(), action, data, status: response.status, statusText: response.statusText })

                res2.json()
                    .then(dataNok => {
                        tokenTest(dataNok)
                    })
                throw new Error('Network response was not ok.')
            }
            
            return res
        });
}

export default crud
export { setCrudStore, tokenTest }