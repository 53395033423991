import Vue from 'vue'
import VueRouter from 'vue-router'
import ContentPage from '@/views/ContentPage.vue'
import ContentEvent from '@/views/ContentEvent.vue'
import HTMLContent from '@/views/HTMLContent.vue'
import GalleryPage from '@/views/Gallery.vue'
import Overview from '@/views/Overview.vue'
import Home from '@/views/Home.vue'
import SplashLogin from '@/views/Splash.vue'
import Weather from '@/views/Weather.vue'
import WeatherOverview from '@/views/WeatherOverview.vue'
import WeatherSingleDay from '@/views/WeatherSingleDay.vue'
import Map from '@/views/Map.vue'
import QRCode from '@/views/QRCode.vue'
import LogoutConfirm from '@/views/LogoutConfirm.vue'
import FeedbackQuestions from '@/views/FeedbackQuestions.vue'
import DebugPage from '@/views/DebugPage.vue'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'splash',
            component: SplashLogin
        },
        {
            path: '/logout/confirm',
            name: 'logout',
            component: SplashLogin
        },
        {
            path: '/logout',
            name:'logouttoconfirmnoevent',
            component: LogoutConfirm
        },
        {
            path: '/events',
            component: ContentEvent,
            children: [
                { path: '', component:Overview, name:'overview' }
            ]
        },
        {
            path: '/event/:eventid/c/:pid',
            component: ContentPage,
            children: [
                { path: '', component:HTMLContent, name:'other' },
                { path: 'f', component:FeedbackQuestions, name:'feedback' },
                { path: 'f/:quid', component:FeedbackQuestions, name:'feedbackquestion' },
                { path: 'g', component:GalleryPage, name:'gallery' },
                { path: 'g/i/:image', component:GalleryPage, name:'singleimage' },
                { path: 'g/v/:video', component:GalleryPage, name:'singlevideo' }
            ]
        },
        {
            path: '/event/:eventid',
            component: ContentPage,
            children: [
                { path: '', component:Home, name:'home' },
                {
                    path: 'weather', component:Weather,
                    children: [
                        { path: '', component:WeatherOverview, name: 'weather' },
                        { path: 'd/:weatherdate', component:WeatherSingleDay, name: 'weathersingleday' }
                    ]
                },
                { path: 'map', component:Map, name:'map' },
                { path: 'map/:mapcenter/:mapzoom', component:Map, name:'mapbounds' },
                { path: 'qrcode', component:QRCode, name:'qrcode' },
                { path: 'logout', component:LogoutConfirm, name:'logouttoconfirm' },
                { path: 'debug', component:DebugPage, name:'debug' }
            ]
        }
    ]
})

router.beforeEach((to, from, next) => {

    if (!store.state.searchedCredentials && to.fullPath !== '/') {
        next('/')
    }

    switch (to.name) {
        case 'debug':
            next()
            break
        case 'splash':
            if (store.state.searchedCredentials && store.state.credentials.jwt !== null) {
                if (typeof store.state.credentials.lastroute === 'string') {
                    next(store.state.credentials.lastroute)
                } else {
                    next('/event/'+ store.state.eventid)
                }
            } else {
                next()
            }
            break
        case 'logout':
            next()
            store.dispatch('cleanCache')
            break
        default:
            if (store.state.searchedCredentials && store.state.credentials.jwt !== null) {
                next()
            } else {
                next(false)
                store.commit('appendError', store.getters.tText('insufficientRights'))
                return
            }
    }

    store.commit('updateLastRoute', to.fullPath)
    store.commit('clearErrors')
})

export default router
